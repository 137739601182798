import {
  init,
  Replay,
  BrowserTracing,
} from '@sentry/browser';

init({
  dsn: 'https://9539d67ed4aa0a08ef75ce4a9978ce1d@o4506333216047104.ingest.sentry.io/4506333245865984',

  environment: process.env.NODE_ENV || 'unknown',
  enabled: process.env.NODE_ENV !== 'development',

  // Alternatively, use `process.env.npm_package_version` for a dynamic release version
  // if your build tool supports it.
  release: 'tsf-web@1.0',
  integrations: [new BrowserTracing(), new Replay()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/[^.]+\.?thestorefront\./],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
