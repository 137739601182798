import axios from 'axios';
import omit from 'lodash/omit';
import { stringify } from 'qs';
import {
  all,
  takeLatest,
  put,
  call,
  select,
  debounce,
} from 'redux-saga/effects';

import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import countBy from 'lodash/countBy';
import orderBy from 'lodash/orderBy';

import {
  SET_PAGE_SEARCH_RESULTS,
  UPDATE_SEARCH_FILTERS,
  LISTING_SEARCH_PERFORMED,
  LISTING_SINGLE_SEARCH_PERFORMED,
  CLEAR_SEARCH_FILTERS,
  listingSearchSuccess,
  listingSingleSearchSuccess,
  listingSearchError,
} from '../actions';

const http = require('http');

const SearchBaseUrl = (typeof window !== 'undefined')
  ? window.env.search_base_url
  : (require('../../../../../lib/environment')).secrets.searchBase;

const defaultParameters = {
  latitude_gteq: 48.7710185696451,
  latitude_lteq: 48.9393095776346,
  longitude_gteq: 2.25618213548966,
  longitude_lteq: 2.41215258970567,
  latitude: 48.8551640736398,
  longitude: 2.33416736259766,
  s: 'size ASC',
  country: 'France',
  city: 'Paris',
  zoom: 11,
  with_markers: true,
  markers_size: 1000,
};

// axios request configuration
// source: https://github.com/axios/axios#request-config
const defaultAxiosConfiguration = {
  // this value should be higher than the LB keepalive timeout
  timeout: 65000,

  // keepAlive pools and reuses TCP connections
  httpAgent: new http.Agent({ keepAlive: true }),
}

function* getListings() {
  const state = yield select();
  const { page, parameters } = state.searchFilters;
  const { locales } = state.env;

  const searchParameters = parseInt(parameters.duration, 10) === 0
    ? omit(parameters, 'duration')
    : parameters;

  try {
    const searchResults = yield call(
      axios.get,
      `${SearchBaseUrl}/v1/listings/search`,
      {
        ...defaultAxiosConfiguration,
        params: {
          page,
          per_page: 40,
          q: {
            ...defaultParameters,
            ...searchParameters,
          },
        },
        paramsSerializer: (params) => stringify(params, { arrayFormat: 'brackets' }),
      },
    );

    // We need the country to set a default locale for filters if no listings are returned
    searchResults.data.data.country = parameters.country;

    // Set listings locale
    const defaultLocale = find(locales, { name: parameters.country }) || find(locales, { name: 'United States' });

    if (!searchResults.data.listings || isEmpty(searchResults.data.listings)) {
      searchResults.data.data.locale = defaultLocale;
    } else {
      const countriesOccurencesMap = countBy(searchResults.data.listings, 'country');
      const arr = Object.keys(countriesOccurencesMap).map((countryName) => ({
        countryName, value: countriesOccurencesMap[countryName],
      }));
      const sorted = orderBy(arr, ['value'], ['desc']);

      searchResults.data.data.locale = find(locales, { name: sorted[0].countryName }) || defaultLocale;
    }

    yield put(listingSearchSuccess(searchResults.data));
  } catch (e) {
    yield put(listingSearchError());
  }
}

function* getListing() {
  const state = yield select();
  const { selectedMarker } = state.map;

  if (!selectedMarker) {
    return;
  }

  try {
    const searchResults = yield call(
      axios.get,
      `${SearchBaseUrl}/v1/listings/${selectedMarker}`,
      {
        ...defaultAxiosConfiguration
      }
    );

    yield put(listingSingleSearchSuccess(searchResults.data));
  } catch (e) {
    // yield put(listingSearchError());
  }
}

export default function* searchFilterSagas() {
  yield all([
    debounce(
      250,
      [LISTING_SEARCH_PERFORMED, UPDATE_SEARCH_FILTERS, CLEAR_SEARCH_FILTERS],
      getListings,
    ),
  ]);

  yield all([
    takeLatest(LISTING_SINGLE_SEARCH_PERFORMED, getListing),
  ]);

  yield all([
    takeLatest(SET_PAGE_SEARCH_RESULTS, getListings),
  ]);
}
